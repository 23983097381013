module.exports = {
  home: 'Home',
  about: 'About',
  aboutUs: 'About us',
  portfolio: 'Portfolio',
  team: 'Team',
  news: 'News',
  contact: 'Contact',
  csr: 'CSR',
  esg: 'ESG',
  jobs: 'Jobs',
  investorLogIn: 'Investor log in',
  linkedinConnect: "Let's connect on LinkedIn",
  ourApproach: 'Our approach',
  careers: 'Careers',
  corpResponsibility: 'Timbo Afrika Foundation',
  returns: 'Returns',
  investmentFocus: 'Investment focus',
  activeApproach: 'The active approach',
  menu: 'Menu',
  openPositions: 'Open positions',
  allRightsReserved: 'All rights reserved',
  madeBy: 'Made by',
  by: 'by',
  howWeWork: 'How we work',
  theACCway: 'The ACC way',
};
