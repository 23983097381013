import React from 'react';
import Layout from './index';
import { addLocaleData } from 'react-intl';

import messages from '../data/messages/en';
import en from 'react-intl/locale-data/en';
import 'intl/locale-data/jsonp/en';
import { initialize } from '../util/cache';

addLocaleData(en);

export default class En extends React.Component {
  constructor(props) {
    super(props)
    initialize('en');
  }

  render() {
    return(
      <Layout
        {...this.props}
          i18nMessages={messages}
      />
    )
  }

}